import { Api } from 'utils';

class ChatService {
		
	static admin = {
		view: async (params?: any) => Api.createResponse('admin/chat/view',params),
		delete: async (params?: any) => Api.createResponse('admin/chat/delete',params),
		edit: async (params?: any) => Api.createResponse('admin/chat/edit',params),
	}

}

export default ChatService;