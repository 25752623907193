import React from 'react';
import { Input, Button, Select, Tooltip } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { PositionService } from 'services';
import { TrashIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
	assign?: boolean;
}

class ModalView extends React.Component<Props> {

	state = {
		san: null,
		positions_deleted: [],
		admin: null,
		original_san: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await PositionService.admin.view({
			san_id: this.props.item?.id
		});
		if (this.state.san != null) {
			for (let i = 0; i < res.san?.positions?.length; i++) {
				// @ts-ignore
				const item = this.state.san?.positions?.find((item: any) => item.id == res.san.positions[i].id);
				if (item && item.position) {
					res.san.positions[i].position = item.position;
				}
			}
		}
		if (res.san.type_id == Constants.SAN_TYPES.BASIC && res.san.positions.find((i: any) => i.position == null)) {
			res.san.positions.sort((a: any, b: any) => {
				if (a.user?.credolab_score > b.user?.credolab_score) {
				    return -1;
				}
				if (a.user?.credolab_score < b.user?.credolab_score) {
				    return 1;
				}
				return 0;
			}).map((i: any, index: number) => i.position = index + 1);
		}
		this.setState({
			san: res.san,
			positions_deleted: res.positions_deleted || [],
			admin: res.admin,
			original_san: JSON.parse(JSON.stringify(res.san))
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		let san: any = this.state.san;
		let positions: any = [...san?.positions];
		if (positions.filter((i: any) => i.position == null).length > 0) {
			Globals.showError("Debe asignar a todos los participantes para continuar");
			return;
		}
		await PositionService.admin.savePositions({
			san_id: this.props.item?.id,
			positions: positions.map((item: any) => {
				return {
					id: item.id,
					position: item.position,
					user_id: item.user_id
				}
			})
		});
		Globals.showSuccess("Se han asignado los puestos correctamente");
		this.props.onClose();
	}

	shuffleArray = (array: any) => {
	    for (let i = array.length - 1; i > 0; i--) {
	        const j = Math.floor(Math.random() * (i + 1));
	        [array[i], array[j]] = [array[j], array[i]];
	    }
	}

	azar = async () => {
		let san: any = this.state.san;
		let positions: any = [...san?.positions];
		let positions_free = [];
		for (let i = 0; i < positions.length; i++) {
			if (!positions.find((item: any) => item.position == (i + 1))) {
				positions_free.push(i + 1);
			}
		}
		this.shuffleArray(positions_free);
		for (let i = 0; i < positions.length; i++) {
			if (positions[i].position == null) {
				positions[i].position = positions_free.pop();
			}
		}
		this.setState({
			san: {
				...san,
				positions
			}
		});
	}

	credolab = async () => {
		let san: any = this.state.san;
		let positions_free: any = [...san?.positions.filter((i: any) => i.position == null)]
		positions_free.sort((a: any, b: any) => {
			if (a.user?.credolab_score > b.user?.credolab_score) {
			    return -1;
			}
			if (a.user?.credolab_score < b.user?.credolab_score) {
			    return 1;
			}
			return 0;
		});
		let positions = [...san?.positions.filter((i: any) => i.position != null),...positions_free];
		for (let i = 0; i < positions.length; i++) {
			if (positions[i].position == null) {
				positions[i].position = i + 1;
			}
		}
		this.setState({
			san: {
				...san,
				positions
			}
		});
	}

	assign = (item: any) => {
		let san: any = this.state.san;
		let positions: any = [...san?.positions];
		let positions_free = [];
		for (let i = 0; i < positions.length; i++) {
			if (!positions.find((item: any) => item.position == (i + 1))) {
				positions_free.push(i + 1);
			}
		}
		const index = positions.findIndex((i: any) => i.id == item?.id);
		if (positions_free.length > 0) {
			positions[index].position = positions_free[0];
			this.setState({
				san: {
					...san,
					positions
				}
			});
		}
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		e.stopPropagation();
		Globals.confirm('¿Desea eliminar al usuario del SAN?',async () => {
			await PositionService.admin.delete({
				position_id: item.id
			});
			Globals.showSuccess("Se ha eliminado al usuario del SAN");
			this.load();
		});
	}

	fillWithAdmin = () => {
		const admin: any = this.state.admin;
		if (admin) {
			let san: any = this.state.san;
			const san_pending = san?.quantity_persons - san?.positions?.length;

			for (let i = 0; i < san_pending; i++) {
				san.positions.push({
					user_id: admin?.id,
					user: admin
				});
			}

			this.setState({
				san
			},() => this.azar());
		}
	}
	
	render() {
		const san: any = this.state.san;
		const original_san: any = this.state.original_san;
		const is_basic = original_san?.type_id == Constants.SAN_TYPES.BASIC;

		let san_pending = san?.quantity_persons - san?.positions?.length;
		if (san_pending < 0) {
			san_pending = 0;
		}

		const show_assign = !is_basic && (original_san?.positions?.filter((i: any) => i.position == null).length > 0 || original_san?.quantity_persons > original_san?.positions?.length);

		return (
			<div id="modal-view-positions">
				<form onSubmit={ this.submit }>
					<div className="row row-positions">
						{
							show_assign && (
								<div className="col-md-6">
									<div className="container-modal-positions">
										<p className="title-san">SAN Nº { this.props.item?.code }</p>
										<p className="title-san" style={{ marginBottom: '10px' }}>Zona: { this.props.item?.city?.name || this.props.item?.state?.name || '' }</p>
										<p className="label">Participantes para posiciones aleatorias</p>
										<div className="container-positions">
											<div className="container-azar">
												{
													san?.positions?.filter((i: any) => i.position == null).map((item: any, index: number) => {
														return (
															<div className={ `item-position left` } onClick={ () => this.assign(item) }>
																<p>{ item?.user?.name + ' ' + item?.user?.lastname }</p>
																<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
																	{
																		item?.user?.document_type && item?.user?.document && (
																			<p>{ (item?.user?.document_type?.name || '') + item?.user?.document }</p>
																		)
																	}
																	<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,item) }>
																		<img src={ TrashIcon } />
																	</a>
																</div>
															</div>
														)
													})
												}
											</div>
											{
												san?.positions?.filter((i: any) => i.position == null).length > 0 && (
													<>
														<div className="text-center">
															<Button type="button" className="btn-azar" onClick={ () => this.azar() }>
																Posiciones al azar
															</Button>
														</div>
														<div className="text-center">
															<Button type="button" className="btn-azar" onClick={ () => this.credolab() }>
																Posiciones por Credolab
															</Button>
														</div>
													</>
												)
											}
										</div>
									</div>
								</div>
							)
						}
						<div className={ !show_assign ? 'col-md-12' : 'col-md-6' }>
							<div className="container-modal-positions">
								{
									!show_assign && (
										<React.Fragment>
											<p className="title-san">SAN Nº { this.props.item?.code }</p>
											<p className="title-san" style={{ marginBottom: '10px' }}>Zona: { this.props.item?.city?.name || this.props.item?.state?.name || '' }</p>
										</React.Fragment>
									)
								}
								<p className="label">Participantes por posición</p>
								<div className="container-positions gray">
									{
										san?.positions?.filter((i: any) => i.position != null).sort((a: any,b: any) => a.position - b.position).map((item: any) => {
											return (
												<div className={ `item-position ${ Constants.IMPORTANT_POSITIONS.indexOf(item.position) != -1 ? 'active' : '' }` }>
													<div>
														<p className="position">{ item.position }º</p>
														<p>{ item?.user?.name + ' ' + item?.user?.lastname } (Credolab: { item?.user?.credolab_score || 0 })</p>
													</div>
													<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
														{
															item?.user?.document_type && item?.user?.document && (
																<p>{ (item?.user?.document_type?.name || '') + item?.user?.document }</p>
															)
														}
														<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,item) }>
															<img src={ TrashIcon } />
														</a>
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>
					{
						show_assign && (
							<React.Fragment>
								{
									san_pending == 0 ? (
										<div className="text-center">
											<Button type="submit">
												Guardar
											</Button>
										</div>
									) : (
										<>
											<p className="message">No se pueden asignar puestos hasta que se inscriban todas las personas requeridas</p>
										</>
									)
								}
							</React.Fragment>
						)
					}
					{
						is_basic && san_pending == 0 && original_san?.quantity_persons > original_san?.positions?.length && (
							<div className="text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						)
					}

					{
						san?.positions.filter((i: any) => i.position == null).length == 0 && san_pending > 0 && (
							<div className="text-center">
								<Button className="btn-fill" type="button" onClick={ () => this.fillWithAdmin() }>
									Llenar espacios vacios con el administrador
								</Button>
							</div>
						)
					}

					{
						this.state.positions_deleted.length > 0 && (
							<>
								<p className="label">Participantes eliminados</p>
								<div className="container-positions gray">
									{
										this.state.positions_deleted.map((item: any) => {
											return (
												<div className={ `item-position` }>
													<div>
														{
															item.position && (
																<p className="position">{ item.position }º</p>
															)
														}
														<p>{ item?.user?.name + ' ' + item?.user?.lastname }</p>
													</div>
													{
														item?.user?.document_type && item?.user?.document && (
															<p>{ (item?.user?.document_type?.name || '') + item?.user?.document }</p>
														)
													}
												</div>
											)
										})
									}
								</div>
							</>
						)
					}
				</form>
			</div>
		)
	}
}

export default ModalView;